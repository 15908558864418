import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { I18nService } from '@app-core/services/i18n.service';
import { environment } from '@src-environments/environment';
import { Logger } from '@app-core/services/logger.service';
import { hideAnimation } from '@app-components/animations/sidenav';
import { Event, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { asyncScheduler } from 'rxjs';
import { filter, observeOn, scan } from 'rxjs/operators';
import { AnalyticsService } from './services/analytics.service';
import { CookieService } from 'ngx-cookie-service';

const log = new Logger('App');

// scroll top navigation beteew modules
interface ScrollPositionRestore {
  event: Event;
  positions: { [K: number]: number };
  trigger: 'imperative' | 'popstate' | 'hashchange';
  idToRestore: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [hideAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  activatedAnalytics: any;

  @ViewChild('contentArea') private contentArea: ElementRef;

  constructor(
    private i18nService: I18nService,
    public router: Router,
    public analyticsService: AnalyticsService,
    public cookiesService: CookieService
  ) { }

  ngOnInit(): void {

    this.activatedAnalytics = this.cookiesService.get('cookiesActivated');
    if (this.activatedAnalytics) {

      JSON.parse(this.activatedAnalytics).forEach(element => {
        if (element.id === '3' && element.checked === true) {
          this.analyticsService.init();
        }
      });

    } else {
      this.cookiesService.deleteAll('/', '.trebolapps.com');
    }

    // scroll top navigation beteew modules
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart || event instanceof NavigationEnd,
        ),
        scan<Event, ScrollPositionRestore>((acc, event) => ({
          event,
          positions: {
            ...acc.positions,
            ...(event instanceof NavigationStart
              ? {
                [event.id]: this.contentArea.nativeElement.scrollTop,
              }
              : {}),
          },
          trigger:
            event instanceof NavigationStart
              ? event.navigationTrigger
              : acc.trigger,
          idToRestore:
            (event instanceof NavigationStart &&
              event.restoredState &&
              event.restoredState.navigationId + 1) ||
            acc.idToRestore,
        })),
        filter(
          ({ event, trigger }) => event instanceof NavigationEnd && !!trigger,
        ),
        observeOn(asyncScheduler),
      )
      .subscribe(({ trigger, positions, idToRestore }) => {
        if (trigger === 'imperative') {
          this.contentArea.nativeElement.scrollTop = 0;
        }

        if (trigger === 'popstate') {
          this.contentArea.nativeElement.scrollTop = positions[idToRestore];
        }
      });

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

}
