import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@app-services/analytics.service';
import { CheckModel } from 'src/app/shared/commons/models/check.model';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-panel',
  templateUrl: './cookies-panel.component.html',
  styleUrls: ['./cookies-panel.component.scss']
})

export class CookiesPanelComponent implements OnInit {

  arrChecksIniciales: CheckModel[] = [
    {
      id: '1',
      value: 'PanelCookies.TextCheckboxTechniques',
      checked: true,
      disabled: true
    },/*
    {
      id: '2',
      value: 'PREFERENCIAS',
      checked: false,
      disabled: false
    }, */
    {
      id: '3',
      value: 'PanelCookies.TextCheckboxAnalysis',
      checked: true,
      disabled: false
    },/*
    {
      id: '4',
      value: 'PUBLICIDAD COMPORTAMENTAL',
      checked: false,
      disabled: false
    } */
  ];

  hiddenCookiesPanel = false;
  activatedAnalytics: any;

  constructor(
    private cookiesService: CookieService,
    private analyticsService: AnalyticsService
  ) { }


  ngOnInit(): void {

    // this.activatedAnalytics = JSON.parse(localStorage.getItem('cookiesActivated'));
    this.activatedAnalytics = this.cookiesService.get('cookiesActivated');
    // console.log(this.activatedAnalytics);
    if (this.activatedAnalytics) {

      JSON.parse(this.activatedAnalytics).forEach(element => {
        if (element.id === '3' && element.checked === true) {
          this.analyticsService.init();
        }
      });

      this.hiddenCookiesPanel = true;
    } else {

      this.cookiesService.deleteAll('/', '.trebolapps.com');
      this.hiddenCookiesPanel = false;
    }
  }

  /**
   * Recibe el valor del check
   * @param $event Valor del check
   * Update the check value to mark that it has been checked
   */

  updateCheckValue($event: any): void {
    const { id: idCheck, checked } = $event;

    this.arrChecksIniciales.forEach(element => {
      if (element.id === idCheck) {
        element.checked = checked;
      }
    });
  }

  activateNecessaryCookies() {
    this.arrChecksIniciales.forEach(element => {
      if (element.id === '1') {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });

    this.activateCookiesHiddenPanel();
  }

  activateSelectionCookies() {

    this.arrChecksIniciales.forEach(element => {
      if (element.id === '3' && element.checked === true) {
        this.analyticsService.init();
      }
    });

    this.activateCookiesHiddenPanel();
  }

  activateAllCookies() {
    this.arrChecksIniciales.forEach(element => {
      element.checked = true;
    });
    this.analyticsService.init();

    this.activateCookiesHiddenPanel();
  }

  deniedAllCookies() {
    this.arrChecksIniciales.forEach(element => {
      if (element.id !== '1') {
        element.checked = false;
      }
    });
    this.activateCookiesHiddenPanel();
  }

  activateCookiesHiddenPanel() {
    // localStorage.setItem('cookiesActivated', JSON.stringify(this.arrChecksIniciales));
    // Expiración cookie 2 años el ultimo dia del mes
    // const now = new Date();
    // const now2 = new Date(now.getFullYear() + 2, now.getMonth() + 1, 0);
    // expires: now2
    this.cookiesService.set('cookiesActivated', JSON.stringify(this.arrChecksIniciales), {expires: 7});
    this.hiddenCookiesPanel = true;
  }
}
