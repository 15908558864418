<ng-container *ngIf="hiddenCookiesPanel === false">
  <div class="container-main">

    <div class="container-cookies">
      <div class="container-img">
        <img width="482" height="489" src="/assets/images/app/cookies/cookie.jpg" class="image-cookie" alt="Cookie">
        <div class="container-title"><h3>{{'PanelCookies.Title' | translate}}</h3></div>

      </div>

      <div class="container-text">

        <p class="text-description">{{'PanelCookies.Description' | translate}}</p>
      </div>
      <div class="container-section-cookies">
        <button (click)="activateNecessaryCookies()" mat-raised-button class="button-card">{{'PanelCookies.AllowButtonCookies' | translate}}</button>
        <button (click)="activateSelectionCookies()" mat-raised-button class="button-card">{{'PanelCookies.AllowButtonCookiesSeleted' | translate}}</button>
<!--
        <button (click)="activateAllCookies()" mat-raised-button class="button-card">Permitir todas las cookies</button>
        <button (click)="deniedAllCookies()" mat-raised-button class="button-card">Denegar todas las cookies</button>
-->
      </div>
      <div class="container-selection-cookies">
        <ng-container *ngFor="let item of arrChecksIniciales">
          <app-checkbox class="check-filter" (objValueCheckInputEvent)="updateCheckValue($event)"
            [esChecked]="item.checked" [esDisabled]="item.disabled" [isDisabled]="item.disabled" [strIdentificador]="item.id"
            [strValor]="item.value">
          </app-checkbox>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
