import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../models/response.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Utils } from '../utils/utils';
import { CONSTANTS } from '../utils/constants';
import { GlobalUserModel } from '@app-models/users/global-user.model';
import { UserSignUpModel } from '@app-models/users/user-sign-up.model';
import { UserStorageModel } from '@app-models/users/user-storage.model';
import { UserChangeRecoveryPasswordModel } from '@app-models/users/user-change-recovery-password.model';
import { HeaderService } from './header.service';
import { UserSignInModel } from '@app-models/users/user-sign-in.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // Observable con los datos globales del usuario
  globalUserSub$: BehaviorSubject<GlobalUserModel> = new BehaviorSubject<GlobalUserModel>(null);
  globalUser$: Observable<GlobalUserModel> = this.globalUserSub$.asObservable();

  // Observable con los datos en el storage del usuario
  currentUserSub$: BehaviorSubject<UserStorageModel> = new BehaviorSubject<UserStorageModel>(null);
  currentUser$: Observable<UserStorageModel> = this.currentUserSub$.asObservable();

  // Observable que informa si el usuario esta logueado
  isUserLoggedInSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUserLoggedIn$: Observable<boolean> = this.isUserLoggedInSub$.asObservable();

  userToken: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private utils: Utils,
    private headerService: HeaderService
  ) { }

  public get currentUserValue(): UserStorageModel {
    return this.currentUserSub$.getValue();
  }

  recoveryPasswordUser(email: any) {
    const options = this.utils.createHeader(CONSTANTS.REQUEST_METHOD.POST);
    return this.http.post<any>(
      CONSTANTS.URL.POST_RECOVERY_PASSWORD_USER,
      email,
      options
    );
  }

  changeRecoveryPassword(userChangeRecoveryPasswordModel: UserChangeRecoveryPasswordModel) {
    const options = this.utils.createHeader(CONSTANTS.REQUEST_METHOD.POST);
    return this.http.post<any>(
      CONSTANTS.URL.POST_CHANGE_RECOVERY_PASSWORD,
      userChangeRecoveryPasswordModel,
      options
    );
  }

  userSignIn(user: UserSignInModel): Observable<any> {
    const options = this.utils.createHeader(CONSTANTS.REQUEST_METHOD.POST);
    return this.http.post<any>(CONSTANTS.URL.POST_USER_SIGN_IN, user, options);
  }

  /**
   * Comprueba si el usuario esta logueado
   */
  checkIfUserIsLoggedIn() {
    this.isUserLoggedInSub$.next(false);
    const userJSON = this.getStorageUserInJSON();
    if (userJSON !== undefined && userJSON.token && userJSON.token !== '') {
      this.validarToken(userJSON.token);
    } else {
      this.headerService.nextValueIconsNavigationBar(false);
    }
  }

  /**
   * Validar el token del usuario
   * Recupera los datos del usuario
   * @param token token del usuario
   */
  async validarToken(token: string) {
    const options = this.utils.createHeaderWithToken(CONSTANTS.REQUEST_METHOD.GET, token);
    this.http.get<ResponseModel>(CONSTANTS.URL.GET_CHECK_VALIDATION_TOKEN, options).subscribe((response: ResponseModel) => {
      if (response.statusResponse.status === CONSTANTS.APP_RESPONSES.OK) {
        this.isUserLoggedInSub$.next(true);

        const userJSON = this.getStorageUserInJSON();
        if (userJSON !== undefined && userJSON.token && userJSON.token !== '') {
          const userStorage: UserStorageModel = new UserStorageModel(userJSON.user_name, userJSON.email, userJSON.token);
          this.http.post<ResponseModel>(CONSTANTS.URL.POST_GLOBAL_USER_DATA, userJSON, options)
            .subscribe((response: ResponseModel) => {
              if (response.statusResponse !== null && response.statusResponse.status !== CONSTANTS.APP_RESPONSES.KO) {
                const userGlobal: GlobalUserModel = response.dataResponse.dataList[0] as GlobalUserModel;
                this.transmitirValorUser(userGlobal);
                this.headerService.nextValueIconsNavigationBar(true);
              }
            });
        }
      } else {
        this.isUserLoggedInSub$.next(false);
        this.headerService.nextValueIconsNavigationBar(false);
      }
    });
  }

  /**
   * Obtiene el usuario almacenado en el storage como objeto JSON
   */
  getStorageUserInJSON(): any {
    const user: string = localStorage.getItem('currentUser');
    let userJSON: any;
    if (user !== null && user !== '') {
      userJSON = JSON.parse(user);
    }
    return userJSON;
  }

  /**
   * Nuevo usuario
   * @param user datos del usuario
   */
  newUser(user: UserSignUpModel) {
    const userData = {
      user_name: user.user_name,
      email: user.email,
      password: user.password
    };
    const options = this.utils.createHeader(CONSTANTS.REQUEST_METHOD.POST);
    return this.http.post(CONSTANTS.URL.POST_CREATE_USER, userData, options);
  }

  leerToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  /**
   * cierre de sesión del usuario
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.router.navigate(['/sign-in']);
  }

  transmitirValorUser(globalUser: GlobalUserModel) {
    this.globalUserSub$.next(globalUser);
    console.log(this.globalUser$);
  }
}
