import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./components/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./components/web-services/web-services.module').then((m) => m.WebServicesModule),
  },
  {
    path: 'works/works',
    loadChildren: () =>
      import('./components/works/works.module').then((m) => m.WorksModule),
  },
  {
    path: 'works/templates',
    loadChildren: () =>
      import('./components/web-templates/templates.module').then((m) => m.TemplatesModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./components/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'faqs',
    loadChildren: () =>
      import('./components/faqs-expanded-panels/faqs-expanded-panels.module').then((m) => m.FaqsExpandedPanelsModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./components/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./components/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'legal-notice',
    loadChildren: () =>
      import('./components/legal-notice/legal-notice.module').then((m) => m.LegalNoticeModule),
  },
  {
    path: 'cookies-policy',
    loadChildren: () =>
      import('./components/cookies-policy/cookies-policy.module').then((m) => m.CookiesPolicyModule),
  },
  {
    path: 'legal-bases-draw',
    loadChildren: () =>
      import('./components/legal-bases-draw/legal-bases-draw.module').then((m) => m.LegalBasesDrawModule),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./components/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./components/sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('./components/sign-up/sign-up.module').then((m) => m.SignUpModule),
  },
  {
    path: 'recovery-password',
    loadChildren: () =>
      import('./components/recovery-password/recovery-password.module').then((m) => m.RecoveryPasswordModule),
  },
  {
    path: 'change-recovery-password/:guid/:email/:newPassword',
    loadChildren: () =>
      import('./components/change-recovery-password/change-recovery-password.module').then((m) => m.ChangeRecoveryPasswordModule),
  },
  {
    path: 'management',
    loadChildren: () =>
      import('./components/management/management.module').then((m) => m.ManagementModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'contract-clauses',
    loadChildren: () =>
      import('./components/contract-clauses/contract-clauses.module').then((m) => m.ContractClausesModule),
  },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
