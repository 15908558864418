import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { StateModel } from '@app-models/state.model';
import { GlobalUserModel } from '@app-models/users/global-user.model';
import { AuthenticationService } from '@app-services/authentication.service';
import { HeaderService } from '@app-services/header.service';
import { Utils } from '@app-utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userData: GlobalUserModel;
  imageUrl = '/assets/images/app/header/user-image-default.png';

  formularioPrueba = new FormGroup({
    combo: new FormControl()
  });

  myControl = new FormControl();

  filteredOptions: Observable<StateModel[]>;

  options: StateModel[] = this.utils.getFlags();

  selected = new StateModel(1, 'es-ES', 'Spain', 'spain.png');

  public isHidden: boolean;
  public isHidden2: boolean;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private utils: Utils,
    @Inject(DOCUMENT) private document: Document,
    private headerService: HeaderService,
    public authenticationService: AuthenticationService
  ) {
    this.authenticationService.checkIfUserIsLoggedIn();
  }

  ngOnInit(): void {

    this.loadLangSelect();

    this.headerService.onlyLogo$.subscribe(x => this.isHidden = !x);
    this.headerService.onlyLogo2$.subscribe(x => this.isHidden2 = !x);


    this.formularioPrueba.get('combo').valueChanges.subscribe(val => {
      this.selected = this.options.filter(option => option.id === val)[0];
      this.translateService.use(this.selected.translation);
    });

    this.authenticationService.globalUser$.subscribe((user: GlobalUserModel) => {
      this.userData = user;
      if (this.userData !== null && this.userData.image.image !== '') {
        this.imageUrl = 'data:' + this.userData.image.type + ';base64,' + this.userData.image.image;
      }

    });
  }

  /**
   * Carga el combo de idiomas
   */
  loadLangSelect(): void {
    const lang = this.translateService.store.currentLang;
    this.selected = this.options.filter(option => option.translation === lang)[0];
    this.formularioPrueba.get('combo').setValue(this.selected);
  }

  cerrarSesion() {
    this.headerService.nextValueIconsNavigationBar(false);
    this.authenticationService.logout();
    this.userData = null;
  }

  iniciarSesion() {
    this.router.navigate(['/sign-in']);
  }

  /**
   * Redirige a la página clickeada
   * @param strPage Página
   */
  goToPageDashboard(strPage: string) {
    this.closeMenu(strPage);
    this.router.navigate(['/dashboard/' + strPage]);
  }

  goToPage(strPage: string) {
    this.closeMenu(strPage);
    this.router.navigate([strPage]);
  }

  goToDashboard(strPage: string) {
    this.closeMenu(strPage);
    this.router.navigate([strPage]);
  }

  goToManagement(strPage: string) {
    this.closeMenu(strPage);
    this.router.navigate([strPage]);
  }

  closeMenu(strPage: string) {
    const isMenuOpen = this.document.querySelector('#menu').getAttribute('aria-expanded') === 'true';
    if (isMenuOpen) {
      const element = this.document.querySelector('#menu') as HTMLElement;
      element.click();
    }

    const elementsMenu: NodeListOf<Element> = this.document.querySelectorAll('#ulMenu li a');

    elementsMenu.forEach((elementLi: Element) => {
      if (elementLi.classList.contains('active')) {
        elementLi.classList.remove('active');
      }
    });

    if (strPage === 'works/works' || strPage === 'works/templates') {
      strPage = 'works';
    }

    const elementMenu: Element = this.document.querySelector('#ulMenu .' + strPage);

    // TODO: controlar que el submenu quede activo
    if (elementMenu) {
      elementMenu.classList.add('active');
    }
  }

}
