import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

// Componentes
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CookiesPanelComponent } from '@app-components/cookies-panel/cookies-panel.component';

// services
import { AuthenticationService } from './services/authentication.service';
import { HeaderService } from './services/header.service';

// pipes
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SearchFilter2Pipe } from './pipes/search-filter2.pipe';

// utils
import { Utils } from './utils/utils';

import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from './shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { CookiesInterceptor } from '@app-interceptors/cookies.interceptor';
import { AnalyticsService } from './services/analytics.service';
import { CookieService } from 'ngx-cookie-service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CookiesPanelComponent,
    SearchFilterPipe,
    SearchFilter2Pipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
    TranslateModule.forRoot(),
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
  ],
  providers: [
    AuthenticationService,
    Utils,
    HeaderService,
    AnalyticsService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookiesInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
