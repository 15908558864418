import { Environment } from "@src-environments/environment.prod";

export const CONSTANTS = {
  URL: {
    POST_CREATE_USER: getCurrentUrl() + '/users/createUser',
    POST_USER_SIGN_IN: getCurrentUrl() + '/users/userSignIn',
    POST_RECOVERY_PASSWORD_USER: getCurrentUrl() + '/users/recoveryPasswordUser',
    POST_CHANGE_RECOVERY_PASSWORD: getCurrentUrl() + '/users/changeRecoveryPassword',
    GET_USERS: getCurrentUrl() + '/users/getUsers',
    GET_CHECK_VALIDATION_TOKEN: getCurrentUrl() + '/users/checkValidationToken',
    POST_GLOBAL_USER_DATA: getCurrentUrl() + '/users/getGlobalUserData',
    POST_UPLOAD_IMAGE: getCurrentUrl() + '/users/uploadImage',
    GET_ROAD_TYPES: getCurrentUrl() + '/roadTypes/getAllRoadTypes',
    GET_COUNTRIES: getCurrentUrl() + '/countries/getAllCountries',
    GET_COMMUNITIES: getCurrentUrl() + '/communities/getAllCommunities',
    GET_COMMUNITIES_BY_ID_PARENT: getCurrentUrl() + '/communities/getCommunitiesByParentId/',
    GET_PROVINCES: getCurrentUrl() + '/provinces/getAllProvinces',
    GET_PROVINCES_BY_ID_PARENT: getCurrentUrl() + '/provinces/getProvincesByParentId/',
    GET_MUNICIPALITIES: getCurrentUrl() + '/municipalities/getAllMunicipalities',
    GET_MUNICIPALITIES_BY_ID_PARENT: getCurrentUrl() + '/municipalities/getMunicipalitiesByParentId/',
    UPDATE_PERSONAL_DATA: getCurrentUrl() + '/users/updatePersonalData',
    UPDATE_PASSWORD_USER: getCurrentUrl() + '/users/updatePasswordUser',
    DELETE_USER: getCurrentUrl() + '/users/deleteUser/',
    GET_ROLES: getCurrentUrl() + '/roles/getRoles',
    POST_ADD_NEW_USER: getCurrentUrl() + '/users/postAddNewUser',
    POST_DELETE_USERS: getCurrentUrl() + '/users/postDeleteUsers/',
    PUT_UPDATE_USER: getCurrentUrl() + '/users/putUpdateUser/',
    GET_POSTS: getCurrentUrl() + '/blog/getAllPosts/',
    GET_CATEGORIES: getCurrentUrl() + '/categories-blog/getCategories',
    POST_ADD_NEW_POST: getCurrentUrl() + '/blog/addPost',
    POST_DELETE_POST: getCurrentUrl() + '/blog/deletePost/',
    POST_DELETE_POSTS: getCurrentUrl() + '/blog/deleteAllPosts',
    PUT_UPDATE_POST: getCurrentUrl() + '/blog/putPostById/',
    POST_SEND_EMAIL: getCurrentUrl() + '/email/sendEmail',
    POST_SEND_EMAIL_CONTACT: getCurrentUrl() + '/send-email'
  },
  REQUEST_METHOD: {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE'
  },
  APP_RESPONSES: {
    OK: 'OK',
    KO: 'KO'
  },
  HTTP_STATUS: {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504
  },
  ENVIRONMENT_URL: {
    CURRENT: 'http://localhost:5000',
    LOCALHOST: 'http://localhost:5000',
    PRODUCTION: 'https://skeleton-panel-back.herokuapp.com'
  }
};

/**
 * Obtiene la url actual
 * @returns retorna la url actual
 */
export function getCurrentUrl(): string {
  return Environment.PathBackEnd();
  // return 'https://skeleton-panel-back.herokuapp.com';
}
