import { ChangeDetectionStrategy, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CheckModel } from '../../models/check.model';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Clase que crea un componente generico de tipo check
 *
 * @member esChecked
 * @member esDisabled
 * @member strValor
 * @member strIdentificador
 * @member strPrefijo
 * @member strIdCheck
 * @member arrValorSeleccionado
 * @member objValueCheckInputEvent
 */
export class CheckboxComponent implements OnInit {
  @Input() esChecked: boolean;
  @Input() esDisabled: boolean;
  @Input() strValor: string;
  @Input() strIdentificador: string;
  @Input() isDisabled: boolean;
  strPrefijo = 'ch';
  strIdCheck: string;
  objCheckData: CheckModel = new CheckModel();
  objElement: HTMLInputElement;

  @Output() objValueCheckInputEvent: EventEmitter<CheckModel> = new EventEmitter<CheckModel>();

  constructor() { }

  /**
   * Crea el identificador del componente y pone el valor por defecto
   */
  ngOnInit(): void {
    this.strIdCheck = this.strPrefijo + this.strIdentificador;
  }

  /**
   * Detecta el cambio al clickear en el checkboxm, guarda sus valores y se envia el objeto resultante al elemento padre
   *
   * @param $event valor del check
   */
  changeValue($event: any) {
    this.objElement = document.querySelector('#' + this.strIdCheck);
    const strChecked = this.objElement.getAttribute('checked');
    const esChecked = !(strChecked === 'true');
    this.objElement.setAttribute('checked', esChecked.toString());
    this.objCheckData.id = this.strIdentificador;
    this.objCheckData.value = this.strValor;
    this.objCheckData.checked = esChecked;
    this.enviarValor();
  }

  /**
   * Envia el valor seleccionado del check, al elemento padre
   */
  enviarValor() {
    this.objValueCheckInputEvent.emit(this.objCheckData);
  }

}
